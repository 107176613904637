import { IonIcon, IonSpinner, IonText } from "@ionic/react";
import { actionUploadTimeCardV2 } from "@src/app/store/ongoingShifts";
import { useToast } from "@src/appV2/lib";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { Shift } from "@src/lib/interface";
import { Moment } from "moment";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { USER_EVENTS } from "../../../constants/userEvents";
import { SelectedFile } from "../../shiftSignature/timecard/model";

const FIVE_MINUTES_IN_MS = 300000;

export function FilePreviewAndUpload(props: {
  selectedFile: SelectedFile;
  shift: Shift;
  shiftStateData?: ShiftStateData;
  onPreviewClosed: () => void;
  onSuccessfulUpload: () => void;
}) {
  const { selectedFile, shift, shiftStateData, onPreviewClosed, onSuccessfulUpload } = props;
  const { file, type, fileBlob } = selectedFile;
  const isImage = ["jpg", "jpeg", "png"].includes(type);

  const dispatch = useDispatch();

  const [uploading, setUploading] = useState<boolean>(false);
  const { showErrorToast } = useToast();

  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const maxTimeout = () => {
    return new Promise((_, reject) => {
      timeoutRef.current = setTimeout(() => {
        reject();
      }, FIVE_MINUTES_IN_MS);
    });
  };

  const uploadFile = async () => {
    const uploadTimeStart = moment();
    try {
      setUploading(true);
      logEvent(
        USER_EVENTS.UPLOAD_TIMESHEET_STARTED,
        generateUploadTimeLog({
          shift,
          file,
          uploadTimeStart,
          isUploadEnd: false,
          isTimeSheetEditDisabled: shiftStateData?.metadata.isTimeSheetEditDisabled,
        })
      );
      await Promise.race([
        maxTimeout(),
        dispatch(actionUploadTimeCardV2(file, type, fileBlob, `${shift._id}`)),
      ]);
      if (shift.isInstantPay) {
        logEvent(USER_EVENTS.SUBMITTED_INSTANTPAY_TIMECARD_PHOTO, {
          instant_pay: shift?.isInstantPay,
        });
      }
      setUploading(false);
      logEvent(USER_EVENTS.UPLOAD_TIMESHEET_SUCCESS, {
        ...generateUploadTimeLog({ shift, file, uploadTimeStart, isUploadEnd: true }),
      });
      onSuccessfulUpload();
    } catch (err) {
      const error = err as Error | string;
      logError("Document File Upload Error", { error });
      logEvent(
        USER_EVENTS.UPLOAD_TIMESHEET_FAILED,
        generateUploadTimeLog({ shift, file, uploadTimeStart, isUploadEnd: true, error })
      );
      if ((error as Error)?.toString().includes("Request has been terminated")) {
        showErrorToast("Fail while uploading, please try again");
      } else {
        showErrorToast((error as Error)?.message || error);
      }
      setUploading(false);
    }
  };

  if (!isImage) {
    return null;
  }

  return (
    <>
      <div className="upload-preview">
        <div role={"button"} tabIndex={0} className="icon" onClick={onPreviewClosed}>
          <IonIcon name="close-circle" />
        </div>
        <img className="uploaded-timesheet" alt="timecard" src={file} />
      </div>
      <div
        role={"button"}
        tabIndex={0}
        className="ion-text-center ion-margin file-upload-container btn"
        style={{ zIndex: 9999 }}
        onClick={() => uploadFile()}
      >
        <IonText>
          {uploading ? (
            <>
              <IonSpinner slot="start" name="crescent" color="dark" />
              <span>Submitting...</span>
            </>
          ) : (
            "Submit Photo"
          )}
        </IonText>
      </div>
    </>
  );
}

const readFileSize = (file: SelectedFile["file"]) => {
  const stringLength = file.length;
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
  const sizeInKb = sizeInBytes / 1000;
  const sizeInMb = sizeInKb / 1000;
  return `${sizeInMb.toFixed(2)} MB`;
};

export function generateUploadTimeLog(params: {
  shift: Shift;
  file: SelectedFile["file"];
  uploadTimeStart: Moment;
  isUploadEnd: boolean;
  error?: Error | string;
  isTimeSheetEditDisabled?: boolean;
}) {
  const { shift, file, uploadTimeStart, isUploadEnd, error, isTimeSheetEditDisabled } = params;

  let uploadTimeEnd;
  let uploadTime = "";
  if (uploadTimeStart && isUploadEnd) {
    uploadTimeEnd = moment();
    uploadTime = moment.duration(uploadTimeEnd.diff(uploadTimeStart)).asSeconds().toString();
  }
  return {
    imageSize: file ? readFileSize(file) : "",
    shiftId: shift._id,
    isInstantPay: `${shift.isInstantPay}`,
    uploadTimeStart: uploadTimeStart?.format ? uploadTimeStart.format("YYYY MMM DD HH:mm:SS") : "",
    uploadTimeEnd: uploadTimeEnd?.format ? uploadTimeEnd.format("YYYY MMM DD HH:mm:SS") : "",
    uploadTime,
    error: error ? `${error}` : "",
    isTimeSheetEditDisabled,
  };
}
